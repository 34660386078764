<template>
  <div>
    <div class="contain1">
      <div>
        设备类型
        <el-select
            clearable
            filterable
            size="mini"
            v-model="search.equipmentType"
            class="m1150"
            placeholder="设备类型"
            @change="inquire"
        >
          <el-option
              v-for="item in equipmentTypeArray"
              :key="item.value"
              :label="item.name"
              :value="item.value"
          >
          </el-option>
        </el-select>
        行政区域
        <el-select
            clearable
            filterable
            size="mini"
            v-model="search.addrName"
            class="m1150"
            placeholder="行政区域"
            @change="inquire"
        >
          <el-option
              v-for="item in addrList"
              :key="item.id"
              :label="item.addrName"
              :value="item.addrName"
          >
          </el-option>
        </el-select>
        状态
        <el-select
            clearable
            filterable
            size="mini"
            v-model="search.state"
            class="m1150"
            placeholder="设备状态"
            @change="inquire"
        >
          <el-option
              v-for="item in statusArray"
              :key="item.value"
              :label="item.name"
              :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input size="mini" style="width:400px;" placeholder="请输入内容" v-model="search.keyword" class="select230">
          <el-select style="width:100px;" v-model="select" slot="prepend" placeholder="请选择" filterable>
            <el-option label="餐厅名" value="1"></el-option>
            <el-option label="订单号" value="2"></el-option>
            <el-option label="用户电话" value="3"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
        <br/>
        安检员
        <el-select
            clearable
            filterable
            size="mini"
            style="margin-left:23px;"
            v-model="search.equipmentType"
            class="m1150"
            placeholder="设备类型"
            @change="inquire"
        >
          <el-option
              v-for="item in equipmentTypeArray"
              :key="item.value"
              :label="item.name"
              :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="inquire"
        >查询</el-button
        >
        <el-button
            size="mini"
            class="ml20"
            icon="el-icon-refresh"
            @click="refresh"
        >重置</el-button
        >
      </div>
    </div>
    <div class="contain2">
      <el-table
          :data="tableData"
          :header-cell-style="{ background: '#f5f7fa' }"
          border
          style="width: 100%"
      >
<!--        <el-table-column prop="equipmentName" label="设备编号"> </el-table-column>-->
        <el-table-column prop="verifyCode" label="工单编号"> </el-table-column>
        <el-table-column prop="equipmentName" label="设备名称"> </el-table-column>
            <el-table-column label="设备类型">
              <template slot-scope="scope">
                {{scope.row.equipmentType == 1?'流量计':''}}
                {{scope.row.equipmentType == 2?'燃气表':''}}
              </template>
            </el-table-column>
        <el-table-column prop="userName" label="所属用户"> </el-table-column>
        <el-table-column prop="userPhone" label="联系方式"></el-table-column>
        <el-table-column prop="addrName" label="行政区域"></el-table-column>
        <el-table-column prop="equipmentLocation" label="设备地址"></el-table-column>
        <el-table-column prop="inspectorName" label="安检员"></el-table-column>
        <el-table-column prop="installTime	" label="安装日期"></el-table-column>
        <el-table-column prop="toVerifyTime" label="待校验日期"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{scope.row.state == 2?'已校验':'未校验'}}
          </template>
        </el-table-column>
        <el-table-column prop="verifyTime" label="校验时间"></el-table-column>
        <el-table-column label="操作" align="left">
          <!-- <template slot-scope="scope">

          </template> -->
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  checkUserSelect,
  selectAddrPullDown,
  selectUserPullDown
} from "../../RequestPort/device/deviceList.js";
import { tableList } from '../../RequestPort/workorder/verifyRecord'
export default {
  name: "verifyRecord",
  data(){
    return {
      tableData:[],
      select:[],
      search:{

      },
      findObj: {

      },
      addrList:[],
      equipmentTypeArray:[
        {name:'流量计',value:1},
        {name:'燃气表',value:2},
      ],
      statusArray:[
        {name:'未校验',value:1},
        {name:'已校验',value:2},
      ],
    }
  },
  mounted() {
    this.objCopy()
    this.getAddrList();
    this.tableListfun();
    selectUserPullDown().then((e)=>{
      console.log(e)
      this.userArray = e.data
    })
    this.baseURL = this.$service.defaults.baseURL;
  },
  methods:{
    indexMethod(index) {
      return (this.search.current - 1) * this.search.size + index + 1;
    },
    tableListfun() {
      tableList(this.findObj).then((res) => {
        const { records, total } = res.data;
        console.log(res)
        this.tableData = records;
        this.total = total;
        if (records.length === 0 && res.data.pages >= 1) {
          this.search.current -= 1;
          this.objCopy()
          this.tableListfun();
        }
      });
    },
    getAddrList() {
      selectAddrPullDown().then((res) => {
        this.addrList = res.data;
      });
    },
    objCopy () {
      for (let key in this.search){
        this.findObj[key] = this.search[key];
      }
    },
    inquire(){
      this.search.current = 1;
      this.objCopy()
      this.tableListfun();
    },
    refresh() {
      this.search = {
        addr: "",
        keyword: "",
        current: 1,
        size: 100,
      };
      this.objCopy()
      this.tableListfun();
    },
  },
}
</script>

<style scoped>

</style>