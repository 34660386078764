import axios from '@/common/js/request'
//gsCheckMaintain/selectGsCheckMaintain
export function tableList(params){   // 列表
    const tableList = axios({
        url: '/gsCheckMaintain/selectGsCheckMaintain',
        method: 'get',
        params
    })
    return tableList
}

